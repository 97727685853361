import { PureComponent } from "react"

export type STEP = "start" | "first" | "step" | "second" | "end"

type StepProps = {
    step: STEP
}
export class Step extends PureComponent<StepProps> {
    constructor(props: any) {
        super(props)
        this.state = {

        }
        this.data = {}
    }
    data: {
        [key: string]: number
    }

    render() {
        let display: string[] = ["s1-com", "sc-in", "sc-in", "sc-in", "s5-com"]
        const step: STEP = this.props.step
        console.log("render Mframe", step)

        // return String(step)
        switch (step) {
            case "start":
                display = ["s1-com", "sc-null", "sc-null", "sc-null", "s5-null"]
                break;
            case "first":
                display = ["s1-com", "sc-in", "sc-null", "sc-null", "s5-null"]
                break;
            case "step":
                display = ["s1-com", "sc-com", "sc-in", "sc-null", "s5-null"]
                break;
            case "second":
                display = ["s1-com", "sc-com", "sc-com", "sc-in", "s5-null"]
                break;
            case "end":
                display = ["s1-com", "sc-com", "sc-com", "sc-com", "s5-com"]
                break;
            default:
                break;
        }

        return <>
            <div className="part_block_full-limit" style={{ display: "flex", justifyContent: "center" }}>
                <div className="part_block_full part_block_step" style={{ maxWidth: 1800 }}>
                    <div className="step-item">
                        {"開始評估"}
                        <img src={`./rtt/res/${display[0]}.png`} />
                    </div>
                    <div className="step-item">
                        {"第一部分"}
                        <img src={`./rtt/res/${display[1]}.png`} />
                    </div>
                    <div className="step-item">
                        {"評估結果"}
                        <img src={`./rtt/res/${display[2]}.png`} />
                    </div>
                    <div className="step-item">
                        {"第二部分"}
                        <img src={`./rtt/res/${display[3]}.png`} />
                    </div>
                    <div className="step-item">
                        {"評估結果"}
                        <img src={`./rtt/res/${display[4]}.png`} />
                    </div>
                </div>
            </div>
        </>

    }
    async componentDidMount() {

    }

}

