import React from "react";
import { MixLoader } from "react-entrance";
import ReactDOM from "react-dom";
// import {client} from "react-dom";
import { ReactWarn2Warn } from 'fuck-react-warning';
import { Report } from "./report";
import { Mframe } from "./mframe";



ReactWarn2Warn()

console.log("entrance.tsx")
console.log("report", document.location.pathname, document.location.pathname.endsWith("report"))
console.log("report/", document.location.pathname, document.location.pathname.endsWith("report/"))

if (document.location.pathname.endsWith("report") || document.location.pathname.endsWith("report/")) {
    new MixLoader({
        rootId: "react-root",
        rootNode: <Report />
    })

} else {
    new MixLoader({
        rootId: "react-root",
        rootNode: <Mframe />
    })
}

