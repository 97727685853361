import "./uikit.min.css"
import "./style.css"
import "./index.less"
import { PureComponent } from "react"
import { Tabs, Carousel } from "antd"
import { loadLogic } from "./js"
import React from "react"
import { STEP, Step } from "./step"

type State = {
    currentTab: number
    score: number
    score2: number
    step: STEP
}

export class Mframe extends PureComponent<any, State> {
    carouselRef: React.RefObject<CarouselRef>
    constructor(props: any) {
        super(props)
        this.carouselRef = React.createRef();
        this.state = {
            currentTab: 1,
            score: 0,
            score2: 0,
            step: "start",
        }
        this.data = {}
    }
    data: {
        [key: string]: number
    }

    render() {
        console.log("render Mframe")

        return <>
            <div className="Mframe">
                <section className="uk-section uk-section-default" style={{ backgroundColor: '#45A4A2', display: "none" }}>
                    <div className="uk-container-no-margin">
                        <div className="part1_block_left">
                            <h1 className="h1_top">「短效氣管舒張劑」</h1>
                            <h1 className="h1_bottom">依賴程度評估測驗</h1>
                            <div className="part1_block_right_m">
                                <img src="./rtt/res/image1.jpg" />
                            </div>
                            此自我測驗的設計是幫助您及您的醫生、護士或藥劑師了解您對於哮喘短效氣管舒張劑*的想法及您是否過於依賴此藥物。<br /><br />
                            <b>此測驗並非醫療建議，在諮詢醫生或專業醫護人員前，請勿停止或更改您的用藥。</b><br /><br />
                            ＊含一種稱為SABA（短效B促效劑）的藥物，處方目的為在出現哮喘症狀時可迅速逐步緩解。是項測驗並非醫療建議，在諮詢醫生或專業醫護人員前，請勿停止或更改您的用藥。
                            <br />
                        </div>
                        <div className="part1_block_right">
                            <img src="./rtt/res/image1.jpg" />
                        </div>
                    </div>
                </section>
                <a name="testing_part" id="testing_part" />
                <section className="uk-section uk-section-default" style={{ backgroundColor: '#F2EEE5' }}>
                    <div className="uk-container-no-margin">
                        <form action="#" method="post" name="testing_form">
                            <input type="hidden" id="actiontype" name="actiontype" defaultValue="submit_testing" />
                            <input type="hidden" id="testID" name="testID" defaultValue />
                            <img src="./rtt/res/image3.jpg" className="testing-image-m" />
                            <div className="uk-position-relative uk-visible-toggle uk-light uk-slideshow" tabIndex={-1}
                                style={{ height: "auto", maxHeight: "unset" }}
                                uk-slideshow="min-height: 300; max-height: 800;draggable:false;">
                                <div className="debug" style={{ display: "none" }}>{`currentTab: ${this.state.currentTab}`}</div>
                                <Carousel ref={this.carouselRef} activeKey={`item-${this.state.currentTab}`} animated={{ inkBar: true, tabPane: true }}>
                                    {/* <div key="项目 1" className="item-1" onTouchMoveCapture={this.touchEndPop.bind(this)}> */}
                                    <div key="项目 1" className="item-1">
                                        <div className="part2_block_left" style={{}}>
                                            <img src="./rtt/res/image3.jpg" />
                                        </div>
                                        <div className="part2_block_right">
                                            <span className="question_title">短效氣管舒張劑依賴程度評估測驗<sup>23</sup></span>
                                            <span className="part_title">第一部分</span>
                                            <h1 className="quiz_title">您對於<span>短效氣管舒張劑</span>的看法</h1>
                                            <div className="question_title">請選出最符合您目前的看法</div>
                                            <div className="part_start_btn">

                                                <input type="button" className="quiz_btn"
                                                    style={{ backgroundImage: `url(./rtt/res/btn-bg.png)`, backgroundRepeat: "no-repeat" }}
                                                    defaultValue="開始評估" onClick={this.partOneAction.bind(this)} />
                                            </div>
                                            <div className="part_content_desc" style={{ display: "none" }}>
                                                由於使用短效氣管舒張劑可以讓您快速感受到症狀的改善，因此許多人過於依賴短效氣管舒張劑並誤認為短效氣管舒張劑是治療哮喘最重要的藥物。但是短效氣管舒張劑卻同時具有好和不好的部分。
                                            </div>
                                            <br />
                                        </div>
                                        <div style={{ clear: 'both' }} />
                                    </div>
                                    <div tab="项目 2" key="item-2" forceRender>
                                        <div className="part2_block_left" style={{}}>
                                            <img src="./rtt/res/image3.jpg" />
                                        </div>
                                        <div className="part2_block_right">
                                            <span className="part_title">第一部分</span>
                                            <h1 className="quiz_title">您對於<span>短效氣管舒張劑</span>的看法</h1>
                                            <div className="question_title">1. 使用短效氣管舒張劑來治療哮喘症狀，是控制哮喘的最佳方法。</div>
                                            <table className="answer_table" width="100%">
                                                <tbody><tr>
                                                    <td width="100%">
                                                        <input className="uk-radio" type="radio" name="part1_q1" id="part1_q1a1" defaultValue={1} onClick={this.partOneAction.bind(this)} />
                                                        <label htmlFor="part1_q1a1">完全不同意</label>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q1" id="part1_q1a2" defaultValue={2} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q1a2">不同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q1" id="part1_q1a3" defaultValue={3} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q1a3">不確定</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q1" id="part1_q1a4" defaultValue={4} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q1a4">同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q1" id="part1_q1a5" defaultValue={5} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q1a5">完全同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%" />
                                                    </tr>
                                                </tbody></table>
                                        </div>
                                        <div style={{ clear: 'both' }} />

                                    </div>
                                    <div tab="项目 3" key="item-3" forceRender>

                                        <div className="part2_block_left" style={{}}>
                                            <img src="./rtt/res/image3.jpg" />
                                        </div>
                                        <div className="part2_block_right">
                                            <span className="part_title">第一部分</span>
                                            <h1 className="quiz_title">您對於<span>短效氣管舒張劑</span>的看法</h1>
                                            <div className="question_title">2. 當隨身攜帶短效氣管舒張劑時，我不擔心哮喘發作。</div>
                                            <table className="answer_table" width="100%">
                                                <tbody><tr>
                                                    <td width="100%">
                                                        <input className="uk-radio" type="radio" name="part1_q2" id="part1_q2a1" defaultValue={1} onClick={this.partOneAction.bind(this)} />
                                                        <label htmlFor="part1_q2a1">完全不同意</label>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q2" id="part1_q2a2" defaultValue={2} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q2a2">不同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q2" id="part1_q2a3" defaultValue={3} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q2a3">不確定</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q2" id="part1_q2a4" defaultValue={4} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q2a4">同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q2" id="part1_q2a5" defaultValue={5} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q2a5">完全同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%" />
                                                    </tr>
                                                </tbody></table>
                                        </div>
                                        <div style={{ clear: 'both' }} />

                                    </div>
                                    <div tab="项目 4" key="item-4" forceRender>
                                        <div className="part2_block_left" style={{}}>
                                            <img src="./rtt/res/image3.jpg" />
                                        </div>
                                        <div className="part2_block_right">
                                            <span className="part_title">第一部分</span>
                                            <h1 className="quiz_title">您對於<span>短效氣管舒張劑</span>的看法</h1>
                                            <div className="question_title">3. 短效氣管舒張劑是我在治療哮喘上唯一可以依靠的藥物。</div>
                                            <table className="answer_table" width="100%">
                                                <tbody><tr>
                                                    <td width="100%">
                                                        <input className="uk-radio" type="radio" name="part1_q3" id="part1_q3a1" defaultValue={1} onClick={this.partOneAction.bind(this)} />
                                                        <label htmlFor="part1_q3a1">完全不同意</label>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q3" id="part1_q3a2" defaultValue={2} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q3a2">不同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q3" id="part1_q3a3" defaultValue={3} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q3a3">不確定</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q3" id="part1_q3a4" defaultValue={4} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q3a4">同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q3" id="part1_q3a5" defaultValue={5} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q3a5">完全同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%" />
                                                    </tr>
                                                </tbody></table>
                                        </div>
                                        <div style={{ clear: 'both' }} />

                                    </div>
                                    <div tab="项目 5" key="item-5" forceRender>
                                        <div className="part2_block_left" style={{}}>
                                            <img src="./rtt/res/image3.jpg" />
                                        </div>
                                        <div className="part2_block_right">
                                            <span className="part_title">第一部分</span>
                                            <h1 className="quiz_title">您對於<span>短效氣管舒張劑</span>的看法</h1>
                                            <div className="question_title">4. 使用短效氣管舒張劑所帶來的好處超過所帶來的風險。</div>
                                            <table className="answer_table" width="100%">
                                                <tbody><tr>
                                                    <td width="100%">
                                                        <input className="uk-radio" type="radio" name="part1_q4" id="part1_q4a1" defaultValue={1} onClick={this.partOneAction.bind(this)} />
                                                        <label htmlFor="part1_q4a1">完全不同意</label>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q4" id="part1_q4a2" defaultValue={2} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q4a2">不同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q4" id="part1_q4a3" defaultValue={3} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q4a3">不確定</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q4" id="part1_q4a4" defaultValue={4} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q4a4">同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q4" id="part1_q4a5" defaultValue={5} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q4a5">完全同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%" />
                                                    </tr>
                                                </tbody></table>
                                        </div>
                                        <div style={{ clear: 'both' }} />

                                    </div>
                                    <div tab="项目 6" key="item-6" forceRender>
                                        <div className="part2_block_left" style={{}}>
                                            <img src="./rtt/res/image3.jpg" />
                                        </div>
                                        <div className="part2_block_right">
                                            <span className="part_title">第一部分</span>
                                            <h1 className="quiz_title">您對於<span>短效氣管舒張劑</span>的看法</h1>
                                            <div className="question_title">5. 相較於吸入式類固醇預防藥物，我更喜歡依靠我的短效氣管舒張劑。</div>
                                            <table className="answer_table" width="100%">
                                                <tbody><tr>
                                                    <td width="100%">
                                                        <input className="uk-radio" type="radio" name="part1_q5" id="part1_q5a1" defaultValue={1} onClick={this.partOneAction.bind(this)} />
                                                        <label htmlFor="part1_q5a1">完全不同意</label>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q5" id="part1_q5a2" defaultValue={2} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q5a2">不同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q5" id="part1_q5a3" defaultValue={3} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q5a3">不確定</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q5" id="part1_q5a4" defaultValue={4} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q5a4">同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part1_q5" id="part1_q5a5" defaultValue={5} onClick={this.partOneAction.bind(this)} />
                                                            <label htmlFor="part1_q5a5">完全同意</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%" />
                                                    </tr>
                                                </tbody></table>
                                        </div>
                                        <div style={{ clear: 'both' }} />

                                    </div>
                                    <div tab="项目 7" key="item-7" forceRender>
                                        <div className="part2_block_left" style={{}}>
                                            <img src="./rtt/res/image3.jpg" />
                                        </div>
                                        <div className="part2_block_right">
                                            <span className="part_title">第一部分</span>
                                            <h1 className="quiz_title">您對於<span>短效氣管舒張劑</span>的看法</h1>
                                            <div id="quiz1_result">
                                                {(() => {
                                                    if (this.state.score > 17) {
                                                        return <div id="quiz1_result1" className="quiz_result">
                                                            <div className="quiz_result_title">高度風險</div>
                                                            <div className="quiz_result_content">過於依賴您的短效氣管舒張劑風險屬高：與許多人一樣，您似乎非常依賴短效氣管舒張劑。如果您一週使用三次或以上，這可以是您的哮喘沒有受到應有之良好控制的徵象，這值得與您的醫生、護士、藥劑師討論您的結果。</div>
                                                        </div>
                                                    }
                                                    else if (this.state.score > 10) {
                                                        return <div id="quiz1_result2" className="quiz_result">
                                                            <div className="quiz_result_title">中度風險</div>
                                                            <div className="quiz_result_content">過於依賴您的短效氣管舒張劑風險屬中等：與許多人一樣，短效氣管舒張劑對您非常重要，但您可能對它依賴有點過高。如果您一週使用三次或以上，這可以是您的哮喘沒有受到應有之良好控制的徵象，這值得與您的醫生、護士、藥劑師討論您的結果。</div>
                                                        </div>
                                                    } else {
                                                        return <div id="quiz1_result3" className="quiz_result">
                                                            <div className="quiz_result_title">低度風險</div>
                                                            <div className="quiz_result_content">過於依賴您的短效氣管舒張劑風險屬低：您並沒有過度依賴短效氣管舒張劑，這是個好消息，請繼續閱讀以確保您沒有任何哮喘控制不佳的徵象。</div>
                                                        </div>
                                                    }
                                                })()}



                                                <div style={{ textAlign: 'center', paddingTop: 20 }}>
                                                    {/*
										<a class="quiz_btn" ></a>
										*/}
                                                    <table width="100%">
                                                        <tbody><tr>
                                                            <td align="center" width="50%"><input type="button" className="re_quiz_btn"
                                                                style={{ backgroundImage: `url(./rtt/res/refresh-bg.png)`, backgroundRepeat: "no-repeat" }}
                                                                defaultValue="重新評估" onClick={this.partOneReset.bind(this)} /></td>
                                                            <td align="center" width="50%"><input type="button" className="quiz_btn"
                                                                style={{ backgroundImage: `url(./rtt/res/btn-bg.png)`, backgroundRepeat: "no-repeat" }}
                                                                defaultValue="下一步" onClick={this.partOneAction.bind(this)} /></td>
                                                        </tr>
                                                        </tbody></table>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ clear: 'both' }} />

                                    </div>
                                    <div tab="项目 8" key="item-8" forceRender>
                                        <div className="part2_block_left" style={{}}>
                                            <img src="./rtt/res/image3.jpg" />
                                        </div>
                                        <div className="part2_block_right">
                                            <span className="part_title">第二部分</span>
                                            <h1 className="quiz_title">使用<span>短效氣管舒張劑</span></h1>
                                            <div className="question_title">1. 過去四週您平均使用短效氣管舒張劑的頻率是多少？</div>
                                            <table className="answer_table" width="100%">
                                                <tbody><tr>
                                                    <td width="100%">
                                                        <input className="uk-radio" type="radio" name="part2_q1" id="part2_q1a1" defaultValue={1} onClick={this.partTwoAction.bind(this)} />
                                                        <label htmlFor="part2_q1a1">從未使用</label>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part2_q1" id="part2_q1a2" defaultValue={2} onClick={this.partTwoAction.bind(this)} />
                                                            <label htmlFor="part2_q1a2">每週2次或更少</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part2_q1" id="part2_q1a3" defaultValue={3} onClick={this.partTwoAction.bind(this)} />
                                                            <label htmlFor="part2_q1a3">每週3次</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part2_q1" id="part2_q1a4" defaultValue={4} onClick={this.partTwoAction.bind(this)} />
                                                            <label htmlFor="part2_q1a4">每週4-5次</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%">
                                                            <input className="uk-radio" type="radio" name="part2_q1" id="part2_q1a5" defaultValue={5} onClick={this.partTwoAction.bind(this)} />
                                                            <label htmlFor="part2_q1a5">每週5次以上 </label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100%" />
                                                    </tr>
                                                </tbody></table>
                                        </div>
                                        <div style={{ clear: 'both' }} />

                                    </div>
                                    <div tab="项目 9" key="item-9" forceRender>
                                        <div className="part2_block_left" style={{}}>
                                            <img src="./rtt/res/image3.jpg" />
                                        </div>
                                        <div className="part2_block_right">
                                            <span className="part_title">第二部分</span>
                                            <h1 className="quiz_title">使用<span>短效氣管舒張劑</span></h1>
                                            <div id="quiz2_result">
                                                {(() => {
                                                    if (this.state.score2 <= 2) {
                                                        return <div id="quiz2_result1" className="quiz_result" >
                                                            <div className="quiz_result_content">
                                                                <div style={{ textAlign: "center" }}>{`您的分數低於3分。`}</div>
                                                                {`當您的分數 ≥ 3分時，您可能已經使用過多的短效氣管舒張劑。這可以是您的哮喘沒有受到應有之良好控制的徵象。請告訴您的醫生、護士、或藥劑師，因為也許有更佳的方式治療您的哮喘。請下載PDF以得悉第一和第二部分分數的完整資訊。`}
                                                            </div>
                                                        </div>
                                                    } else {
                                                        return <div id="quiz2_result2" className="quiz_result"  >
                                                            <div className="quiz_result_content">
                                                                <div style={{ textAlign: "center" }}>{`您的分數是3分或以上。`}</div>
                                                                {"當您的分數 ≥ 3分時，您可能已經使用過多的短效氣管舒張劑。這可以是您的哮喘沒有受到應有之良好控制的徵象。請告訴您的醫生、護士、或藥劑師，因為也許有更佳的方式治療您的哮喘。請下載PDF以得悉第一和第二部分分數的完整資訊。"}
                                                            </div>
                                                        </div>
                                                    }
                                                })()}



                                                <div id="quiz_result_pdf" onClick={this.openReport.bind(this)} style={{ textAlign: 'center', paddingTop: 10 }}>
                                                    <a target="_blank" className="pdf_btn"
                                                        style={{ backgroundImage: `url(./rtt/res/download-btn-bg.png)`, backgroundRepeat: "no-repeat", display: "inline-block" }}>下載您的評估測驗</a>
                                                </div>
                                                <div style={{ textAlign: 'center', paddingTop: 10 }}>
                                                    <input type="button" className="quiz_btn"
                                                        style={{ backgroundImage: `url(./rtt/res/btn-bg.png)`, backgroundRepeat: "no-repeat", display: "inline-block" }}
                                                        defaultValue="重新評估" onClick={this.partTwoReset.bind(this)} />
                                                </div>
                                                <div style={{ textAlign: 'center', paddingTop: 20, display: 'none' }}>
                                                    <table width="100%">
                                                        <tbody><tr>
                                                            <td align="center" width="50%"><input type="button" className="re_quiz_btn" defaultValue="重新評估" onclick="javascript: RestartTesting();" /></td>
                                                            <td align="center" width="50%"><input type="button" className="quiz_btn"
                                                                style={{ backgroundImage: `url(./rtt/res/btn-bg.png)`, backgroundRepeat: "no-repeat" }}
                                                                defaultValue="下一步" onclick="javascript: NextTesting();" /></td>
                                                        </tr>
                                                        </tbody></table>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ clear: 'both' }} />

                                    </div>
                                </Carousel>;

                                <a className="uk-position-center-left uk-position-small uk-hidden-hover uk-icon uk-slidenav-previous uk-slidenav"
                                    id="left-question"
                                    href="#"
                                    uk-slidenav-previous
                                    uk-slideshow-item="previous">
                                    <svg width={14} height={24} viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg"><polyline fill="none" stroke="#000" strokeWidth="1.4" points="12.775,1 1.225,12 12.775,23 " /></svg>
                                </a>
                                <a className="uk-position-center-right uk-position-small uk-hidden-hover uk-icon uk-slidenav-next uk-slidenav"
                                    id="next-question" href="#" uk-slidenav-next uk-slideshow-item="next">
                                    <svg width={14} height={24} viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg">
                                        <polyline fill="none" stroke="#000" strokeWidth="1.4" points="1.225,23 12.775,12 1.225,1 " /></svg>
                                </a>
                                <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin">
                                    <li uk-slideshow-item={0} className="uk-active"><a href="https://hkasthma.org.hk/rrt/" /></li>
                                    <li uk-slideshow-item={1}><a href="https://hkasthma.org.hk/rrt/" /></li>
                                    <li uk-slideshow-item={2}><a href="https://hkasthma.org.hk/rrt/" /></li>
                                    <li uk-slideshow-item={3}>
                                        <a href="https://hkasthma.org.hk/rrt/" />
                                    </li>
                                    <li uk-slideshow-item={4}><a href="https://hkasthma.org.hk/rrt/" /></li>
                                    <li uk-slideshow-item={5}><a href="https://hkasthma.org.hk/rrt/" /></li>
                                    <li uk-slideshow-item={6}><a href="https://hkasthma.org.hk/rrt/" /></li>
                                    <li uk-slideshow-item={7}><a href="https://hkasthma.org.hk/rrt/" /></li>
                                    <li uk-slideshow-item={8}><a href="https://hkasthma.org.hk/rrt/" /></li>
                                </ul>
                            </div>
                        </form>
                        {/* <div style={{ clear: 'both' }}><br /></div> */}
                        <Step step={this.state.step} />
                    </div>
                </section>
                <section className="uk-section uk-section-default" style={{ backgroundColor: '#FFFFFF', display: "none" }}>
                    <div className="uk-container-no-margin">
                        <div className="part3_block_left">
                            <span style={{ color: '#45A4A2' }}>有哪些哮喘控制不佳的其他徵狀呢？<sup>2</sup></span><br />
                            在使用短效氣管舒張劑一週三次或以上以外，仍有三個其他哮喘控制不佳的徵狀，請回顧過去四週是否有以下情形，這值得與您的醫生丶護士丶藥劑師討論如何更好地控制您的哮喘病狀。<br /><br />
                            <div className="part3_list">
                                <img src="./rtt/res/icon1.png" className="icon" /> 每週白天症狀出現三次或以上<br />
                                <img src="./rtt/res/icon2.png" className="icon" /> 晚上因哮喘症狀而夜醒<br />
                                <img src="./rtt/res/icon3.png" className="icon" /> 每週使用3次或以上短效氣管舒張劑<br />
                                <img src="./rtt/res/icon4.png" className="icon" /> 因哮喘而限制您的活動<br />
                            </div>
                        </div>
                        <div className="part3_block_right">
                            <img src="./rtt/res/image2.png" />
                        </div>
                        <div style={{ clear: 'both' }} />
                        <div className="part_block_full">
                            <b>在正確的治療下，大多數的人可良好地控制您的哮喘病狀。</b><br />
                            <div className="desc">注意：準則採用實用的數值，即使用緩解劑來緩解症狀的次數達每週三天或以上，來定義未受控制的 (NICE 2017)<sup>1</sup> 或部分控制/未受制的(GINA 2019)<sup>2</sup> 哮喘。<span style={{ color: '#45A4A2' }}>這份5個問題的問卷，是根據倫敦大學學院的行為醫學領域專家Rob Horne教授建立和設計的藥物觀念問卷(Beliefs about Medicines Ouestionnaire)<sup>5</sup> 而改編，問卷已經過驗證且在全球使用。</span>IPCRG已獲得阿斯利康資助來發展哮喘正確護理計劃（Asthma Right Care Initiative)。此工具之製作和發佈由英國阿斯利康全額資助。</div>
                            <div className="reference"><sup>1</sup> NICE Guideline [NG80]. Asthma: diagnosis, monitoring and chronic asthma management. November 2017. <sup>2</sup> Global Initiative for Asthma (GINA) Pocket Guide for asthma management and prevention (for adults and children older than 5 years). 2019. Available from: www.ginasthma.org [last accessed 08.05.19]. <sup>3</sup> Price D, Fletcher M, van der Molen T. NPJ Prim Care Respir Med. 2014; 24:14009. <sup>4</sup> Partridge MR, van der Molen T, Myrseth SE, et al. BMC Pulm Med. 2006; 6:13. <sup>5</sup> Horne R, Weinman J, Hankins M. Psychology &amp; Health. 1999; 14(1):1-24
                            </div>
                        </div>
                    </div>
                </section>
                <section className="uk-section uk-section-default uk-section-bottom" style={{ backgroundColor: '#F2EEE5', display: "none" }}>
                    <div className="uk-container-no-margin">
                        <div className="part4_block_full">
                            <div className="part4_block_col5_1">
                                <img src="./rtt/res/bottom_logo1.png" />
                            </div>
                            <div className="part4_block_col5_1">
                                <img src="./rtt/res/bottom_logo2.png" />
                            </div>
                            <div className="part4_block_col5_1">
                                <img src="./rtt/res/bottom_logo4.png" />
                            </div>
                            <div className="part4_block_col5_1">
                                <img src="./rtt/res/bottom_logo3.png" />
                            </div>
                            <div className="part4_block_col5_1">
                                <img src="./rtt/res/bottom_logo5.png" />
                            </div>
                        </div>
                        <div style={{ clear: 'both' }}><br /><br /></div>
                        <div className="part4_block_full">
                            <div className>
                                <img src="./rtt/res/logo4.jpg" style={{ maxWidth: 280 }} />
                            </div>
                        </div>
                        <div style={{ clear: 'both' }}><br /><br /></div>
                        <div className="part4_block_full" style={{ maxWidth: 1100, margin: '0 auto', textAlign: 'center' }}>
                            Developed by leading expert in behavioral medicine, Professor Rob Horne, University College London (UCL), with colleagues from the international Primary Care Respiratory Group (IPCRG), and fully funded by AstraZeneca UK Limited
                        </div>
                    </div>
                </section>
            </div >
        </>

    }
    async componentDidMount() {
        // loadLogic()

        const current = this.carouselRef.current
        if (current) {
            // console.log("current", current.innerSlider.list)
            const target = current.innerSlider.list as HTMLDivElement
            const slickTracks = target.getElementsByClassName("slick-track")
            const slides = target.getElementsByClassName("slick-slide")
            Array.prototype.forEach.call([...slickTracks, ...slides], (element: HTMLDivElement) => {
                // element.style.background = "red";
                element.addEventListener("touchmove", this.touchEndPop.bind(this), { passive: false });
            });
            // Array.prototype.forEach.call(slides, (element: HTMLDivElement) => {
            //     element.style.background = "red";
            //     element.addEventListener("touchmove", this.touchEndPop.bind(this), { passive: false });
            // });
        }
    }
    partOneReset() {
        const current = this.carouselRef.current
        if (current) {
            setTimeout(() => {
                current.goTo(0, true)
            }, 200);
        }
        this.setState({
            step: "first",
            score: 0,
            currentTab: 1
        })
    }
    //第一部分前进
    partOneAction(event: any) {
        const theEvent = event as Event
        const tatget = theEvent.target
        if (tatget instanceof HTMLInputElement) {
            console.log("tatget", tatget)
            const value = Number(tatget.getAttribute("value"))
            const name = tatget.getAttribute("name")
            if (value >= 0 && name !== null) {
                console.log("value", value)
                this.setState({
                    score: this.state.score + value
                })
                this.data[name] = value
            }
        }


        const current = this.carouselRef.current
        if (current) {
            setTimeout(() => {
                current.next()
            }, 200);
        }
        this.setState({
            step: "first",
            currentTab: this.state.currentTab + 1
        })
        console.log("this.state.currentTab_", this.state.currentTab)

        if (this.state.currentTab === 6) {
            this.setState({ step: "step", })
        } else if (this.state.currentTab === 7) {
            this.setState({ step: "second", })
        }


    }
    partTwoReset() {
        const current = this.carouselRef.current
        if (current) {
            setTimeout(() => {
                current.goTo(0, true)
            }, 200);
        }
        this.setState({
            step: "first",
            score2: 0,
            currentTab: 1
        })
    }
    //第二部分前进
    partTwoAction(event: any) {
        const theEvent = event as Event
        const tatget = theEvent.target
        if (tatget instanceof HTMLInputElement) {
            console.log("tatget", tatget)
            const value = Number(tatget.getAttribute("value"))
            const name = tatget.getAttribute("name")
            if (value >= 0 && name !== null) {
                console.log("value", value)
                this.setState({
                    score2: value
                })
                this.data[name] = value
            }
        }


        const current = this.carouselRef.current
        if (current) {
            setTimeout(() => {
                current.next()
            }, 200);
        }
        this.setState({
            step: "second",
            currentTab: this.state.currentTab + 1
        })
        console.log("this.state.currentTab", this.state.currentTab)
        if (this.state.currentTab === 8) {
            this.setState({ step: "end", })
        }
    }
    async openReport() {
        const data = JSON.stringify(this.data)


        console.log("openReport", data)
        const A = document.createElement("a")
        A.target = "_blank"
        A.href = `./report?data=${encodeURIComponent(data)}`

        const data2 = JSON.stringify({
            ...this.data,
            url: A.href
        })


        fetch("/api/rtt", {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json"
            },
            "body": JSON.stringify({
                "token": "RTT",
                "content": data2
            })
        })



        A.click()

    }
    touchEndPop(event: TouchEvent) {
        event.stopPropagation()
        // this.carouselRef
    }
}
