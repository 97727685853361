
import React from 'react';
import { PureComponent } from 'react';
import { Document, Page } from 'react-pdf';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { pdfjs } from 'react-pdf';
import "./index.less"
pdfjs.GlobalWorkerOptions.workerSrc = './rtt/res/pdf.worker.min.js';


type DataType = { key: string, value: number }
type State = {
    part1: DataType[]
    part1Total: number
    part2: DataType[]
}
export class Report extends PureComponent<any, State> {
    ref: React.RefObject<Document>;
    title: string;
    constructor(props: any) {
        super(props)
        this.ref = React.createRef()
        this.title = ""
        this.state = {
            part1: [],
            part1Total: 0,
            part2: [],
        }
    }

    render() {
        console.log("render Report")
        console.log("render ", this.state)
        const background = undefined
        return <>
            <div>
                <Document
                    ref={this.ref}
                    renderMode={"svg"}
                    file="./rtt/res/0.pdf"
                    onLoadSuccess={this.onLoadSuccess.bind(this)}
                    onLoadError={(event) => { console.log(event) }}
                    className={"Report-Document"}

                >
                    <Page pageNumber={1} width={794} />
                    <Page pageNumber={2} width={794} />
                </Document>
                <div style={{
                    lineHeight: "42px",
                    fontSize: 34,
                    textAlign: "center",
                    color: "#014D9E",
                    fontWeight: 500,
                }}>
                    <div style={{
                        position: "absolute",
                        width: 42,
                        left: 690,
                        top: 449,
                        background

                    }}>
                        {Array.from(this.state.part1, (item) => {
                            return <div style={{ marginBottom: 23.5, }}>
                                {item.value}
                            </div>
                        })}
                    </div>
                    <div style={{
                        position: "absolute",
                        width: 42,
                        left: 690,
                        top: 772,
                        background
                    }}>
                        <div style={{ marginBottom: 23.5, }}>
                            {this.state.part1Total}
                        </div>
                    </div>
                    <div style={{
                        position: "absolute",
                        width: 42,
                        left: 690,
                        top: 955,
                        background
                    }}>
                        {Array.from(this.state.part2, (item) => {
                            return <div style={{ marginBottom: 23.5, }}>
                                {item.value}
                            </div>
                        })}
                    </div>

                </div>

            </div>
        </>
    }




    componentDidMount(): void {
        const input = new URL(document.location.href).searchParams.get("data") || ""
        const json = JSON.parse(input)
        console.log("json", json)
        const part1: DataType[] = []
        let part1Total = 0
        const part2: DataType[] = []
        for (let itemKey in json) {
            if (itemKey.indexOf("part1") != -1) {
                part1.push({
                    value: json[itemKey],
                    key: itemKey
                })
                part1Total = part1Total + json[itemKey]
            } else {
                part2.push({
                    value: json[itemKey],
                    key: itemKey
                })
            }
        }
        this.setState({
            part1Total,
            part1,
            part2,
        })

        this.title = `TR101-${window.crypto.randomUUID()}`
        document.title = this.title 
        
    }
    onLoadSuccess() {
        document.title = this.title 
      
        setTimeout(() => {
            document.title = this.title 
            window.print()
        }, 1800);
    }

}

